<template>
  <div class="container">
    <div class="header">
      <div class="title">产品详情</div>
    </div>
    <div class="content" v-if="info">
      <!-- <el-image class="banner-img" :src="info.detailImgUrl" fit="contain"></el-image> -->
      <el-image class="banner-img" :src="info.imgUrl" fit="contain"></el-image>
      <div class="goods-name">配件名称：{{info.name}}</div>
      <div class="goods-table">
        <div class="tr">
          <div class="td">索道型号:{{info.cablewayName}}</div>
          <div class="td">备品备件型号:{{info.sparePartsName}}</div>
        </div>
        <div class="tr">
          <div class="td">制造厂商:{{info.factoryOwner}}</div>
          <div class="td">单位名称:{{info.company}}</div>
        </div>
        <div class="tr">
          <div class="td">数量(件/台):{{info.num}}</div>
          <div class="td"></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <span>Copyright 2013—2022四川省特种设备行业网四川特种设备信息网四川省特种设备安全管理协会公益组织All Rights Reserved．</span>
      <span>蜀ICP备11027040号四川省特种设备安全管理协会版权所有未经允许严禁转载</span>
    </div>
  </div>
</template>

<script>
import router from '../router'
import axios from 'axios'

export default {
  name: "DetailView",
  data() {
    return {
      info: null,
      params: {}
    }
  },
  created() {
    this.params = router.currentRoute.params
    this.query()
  },
  methods: {
    query() {
      axios.get('/api/apiIndex/queryGoodDetail', {params: this.params}).then(res => {
        if (res?.data?.code === 200) {
          this.info = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
}

.header {
  width: 100%;
  height: 80px;
  background-color: #193984;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .title {
    text-align: left;
    padding-left: 18px;
    width: 1150px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 29px;
    color: #FFFFFF;
    position: relative;
    height: 30px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 8px;
      height: 20px;
      background-color: #FFFFFF;
    }
  }
}

.content {
  flex-grow: 1;
  width: 1150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 30px;

  .banner-img {
    width: 1150px;
    height: 500px;
  }

  .goods-name {
    width: 100%;
    text-align: left;
    padding: 20px 0;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 25px;
    color: #000000;
  }

  .goods-table {
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 138px;
    border-left: 1px solid #DBDBDB;
    border-right: 1px solid #DBDBDB;

    .tr:nth-of-type(1) {
      width: 100%;
      height: 60px;
      border-top: 1px solid #DBDBDB;
      border-bottom: 1px solid #DBDBDB;
      margin: -1px 0 0 0;
    }

    .tr {
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #DBDBDB;
      margin: 0 0 -1px 0;
      display: flex;
      align-items: center;
      flex: 0 0 auto;

      .td:nth-of-type(1) {
        width: 50%;
        height: 100%;
        border-right: 1px solid #DBDBDB;
        margin: 0 -1px 0 0;
      }

      .td {
        text-align: left;
        padding: 15px 20px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
      }
    }
  }
}

.footer {
  width: 100%;
  height: 86px;
  background-color: #2568EC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 26px;
  color: #FFFFFF;
}
</style>