<template>
  <div class="container">
    <div class="header">
      <el-input class="search-input" placeholder="请输入备品备件名称" prefix-icon="el-icon-search" v-model="queryParams.name"></el-input>
      <el-button class="search-btn" type="primary" @click="search">搜索</el-button>
    </div>
    <div class="content">
      <!-- 轮播图 -->
      <el-carousel arrow="never" height="500px" style="width: 1150px;" v-if="bannerList && bannerList.length">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <el-image class="banner-img" :src="item.imgUrl" fit="contain"></el-image>
        </el-carousel-item>
      </el-carousel>
      <!-- 筛选 -->
      <div class="filter">
        <el-select v-model="queryParams.cablewayId" placeholder="索道型号" @change="search" clearable>
          <el-option
            v-for="item in cablewayList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select style="margin-left: 12px;" v-model="queryParams.sparePartsId" placeholder="备品备件型号" @change="search" clearable>
          <el-option
            v-for="item in sparePartsList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="data-list">
        <router-link class="goods" v-for="item in list" :key="item.id" :title="item.name" :to="'/detail/'+item.id">
          <el-image class="goods-img" :src="item.imgUrl" fit="fill"></el-image>
          <div class="goods-name">{{item.name}}</div>
        </router-link>
        <!-- <div class="goods" v-for="item in list" :key="item.id" :title="item.name">
        </div> -->
      </div>
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="queryParams.pageSize" @current-change="pageChange"></el-pagination>
    </div>
    <div class="footer">
      <span>Copyright 2013—2022四川省特种设备行业网四川特种设备信息网四川省特种设备安全管理协会公益组织All Rights Reserved．</span>
      <span>蜀ICP备11027040号四川省特种设备安全管理协会版权所有未经允许严禁转载</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "HomeView",
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 16,
        cablewayId: undefined,
        sparePartsId: undefined,
        name: undefined
      },
      bannerList: [],
      cablewayList: [],
      sparePartsList: [],
      list: [],
      total: 0
    }
  },
  created() {
    axios.get('/api/apiIndex/queryBannerList').then(res => {
      if (res?.data?.code === 200) {
        this.bannerList = res.data.data
      }
    })
    axios.get('/api/apiIndex/queryCablewayList').then(res => {
      if (res?.data?.code === 200) {
        this.cablewayList = res.data.data
      }
    })
    axios.get('/api/apiIndex/querySparePartsList').then(res => {
      if (res?.data?.code === 200) {
        this.sparePartsList = res.data.data
      }
    })
    this.search()
  },
  methods: {
    search() {
      this.list = []
      axios.get('/api/apiIndex/queryGoodList', {params: this.queryParams}).then(res => {
        if (res?.data?.code === 200) {
          this.list = res.data.rows
          this.total = res.data.total
        }
      })
    },
    pageChange(v) {
      this.queryParams.pageNum = v
      this.search()
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
}

.header {
  width: 100%;
  height: 80px;
  background-color: #193984;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .search-input {
    width: 546px;
    font-size: 16px;
    &::v-deep .el-input__inner {      
      height: 50px;
    }
    &::v-deep .el-input__icon {
      font-size: 20px;
      color: #A7A6A6;
      line-height: 50px;
    }
  }

  .search-btn {
    height: 50px;
    width: 97px;
    margin-left: 23px;
  }
}

.content {
  flex-grow: 1;
  width: 1150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 30px;

  .banner-img {
    width: 1150px;
    height: 500px;
  }

  .filter {
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
  }

  .data-list {
    width: 1186px;
    margin: 0 -18px 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .goods {
      border-radius: 6px;
      width: 260px;
      height: 150px;
      margin: 0 18px 25px;
      background-color: #A7A6A6;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      .goods-img {
        width: 260px;
        height: 150px;
      }

      .goods-name {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 34px;
        background: rgba(0,0,0,0.39);
        padding-left: 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 34px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
}

.footer {
  width: 100%;
  height: 86px;
  background-color: #2568EC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 26px;
  color: #FFFFFF;
}
</style>
